<template>
	<v-container fluid class="text-justify" style="max-width: 1200px">
		<div class="mx-2 my-3">
			<span class="text-h3">{{ $t('policies.terms') }}</span>
		</div>

		<v-divider class="my-2" />
		<v-btn text :to="{ name: 'Policies' }">
			{{ $t('policies.goBack') }}
		</v-btn>
		<div class="my-10">
			<p>
				¡Gracias por usar OverSOS! Estamos felices de que estés aquí. Lee atentamente este acuerdo de Términos de servicio antes de acceder o utilizar
				OverSOS. Debido a que es un contrato tan importante entre nosotros y nuestros usuarios, hemos tratado de hacerlo lo más claro posible. Para tu
				comodidad, hemos presentado estos términos en un breve resumen no vinculante seguido de los términos legales completos.
			</p>
			<h4>Equipo a cargo:</h4>
			<p>
				El contenido de este sitio web, así como todos los sitios web en el dominio oversos.com, pertenece a OverSOS, marca nacional registrada, y
				está gestionado por Internet Network Solutions SL, con domicilio social en España, en c/ Països Catalans 4 C, 3º 2ª, Sant just Desvern 08960,
				Barcelona y CIF B-67367987, empresa inscrita en el registro mercantil de Barcelona, con Tomo 46742, sección GENERAL, número 1, folio 203 y
				hoja número B-529470.
			</p>
		</div>
		<div class="my-10">
			<h2 class="terms-header">
				Resumen
			</h2>
			<v-simple-table class="mx-auto my-3">
				<template v-slot:default>
					<thead>
						<tr>
							<th class="text-center">
								Sección
							</th>
							<th class="text-center">
								¿Qué puedes encontrar allí?
							</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td><a style="text-decoration: none" href="#a-definitions">A. Definiciones</a></td>
							<td>
								Algunos términos básicos, definidos de una manera que te ayudarán a comprender este acuerdo. Consulta nuevamente esta sección para
								aclaraciones.
							</td>
						</tr>
						<tr>
							<td><a style="text-decoration: none" href="#b-account-terms">B. Términos de la cuenta</a></td>
							<td>
								Estos son los requisitos básicos para tener una cuenta en OverSOS.
							</td>
						</tr>
						<tr>
							<td><a style="text-decoration: none" href="#c-acceptable-use">C. Uso aceptable</a></td>
							<td>
								Estas son las reglas básicas que deben seguirse al usar tu cuenta en OverSOS.
							</td>
						</tr>
						<tr>
							<td>
								<a style="text-decoration: none" href="#d-user-generated-content">D. Contenido generado por el usuario</a>
							</td>
							<td>
								Tú posees el contenido que publicas en OverSOS. Sin embargo, tienes algunas responsabilidades al respecto y te pedimos que nos
								otorgues algunos derechos para que podamos brindarte servicios.
							</td>
						</tr>
						<tr>
							<td><a style="text-decoration: none" href="#e-course-folders">E. Carpeta de asignatura</a></td>
							<td>
								Esta sección habla sobre cómo OverSOS tratará el contenido que ves y cargas en la carpeta de una asignatura.
							</td>
						</tr>
						<tr>
							<td>
								<a style="text-decoration: none" href="#f-copyright-infringement-and-dmca-policy">F. Política de derechos de autor y DMCA</a>
							</td>
							<td>
								Esta sección habla sobre cómo responderá OverSOS si crees que alguien está infringiendo tus derechos de autor en OverSOS.
							</td>
						</tr>
						<tr>
							<td>
								<a style="text-decoration: none" href="#g-intellectual-property-notice">G. Aviso de propiedad intelectual</a>
							</td>
							<td>
								Describe los derechos de OverSOS en el sitio web y el servicio.
							</td>
						</tr>
						<tr>
							<td>
								<a style="text-decoration: none" href="#h-advertising-on-OverSOS">H. Publicidad en OverSOS</a>
							</td>
							<td>
								Si deseas promocionar tu contenido en OverSOS, esta sección establece algunas reglas que debes seguir.
							</td>
						</tr>
						<tr>
							<td>
								<a style="text-decoration: none" href="#i-payment">I. Pago</a>
							</td>
							<td>
								Eres responsable del pago. Somos responsables de facturarte con exactitud.
							</td>
						</tr>
						<tr>
							<td>
								<a style="text-decoration: none" href="#j-cancellation-and-termination">J. Cancelación y rescisión</a>
							</td>
							<td>
								Puedes cancelar este acuerdo y cerrar tu cuenta en cualquier momento.
							</td>
						</tr>
						<tr>
							<td>
								<a style="text-decoration: none" href="#k-communications-with-OverSOS">K. Comunicaciones con OverSOS</a>
							</td>
							<td>
								Sólo usamos el correo electrónico y otros medios electrónicos para mantenernos en contacto con nuestros usuarios. No proporcionamos
								soporte telefónico.
							</td>
						</tr>
						<tr>
							<td>
								<a style="text-decoration: none" href="#l-disclaimer-of-warranties">L. Renuncia de garantías</a>
							</td>
							<td>
								Brindamos nuestro servicio "tal cual" y no hacemos promesas ni garantías sobre este servicio.
								<strong>Por favor lee esta sección atentamente; Debes entender qué esperar.</strong>
							</td>
						</tr>
						<tr>
							<td>
								<a style="text-decoration: none" href="#m-limitation-of-liability">M. Limitación de responsabilidad</a>
							</td>
							<td>
								No seremos responsables de los daños o pérdidas que surjan de tu uso o incapacidad para usar el servicio o que surjan de acuerdo con
								este acuerdo.
								<strong>Por favor lee esta sección atentamente; Limita nuestras obligaciones para contigo.</strong>
							</td>
						</tr>
						<tr>
							<td>
								<a style="text-decoration: none" href="#n-release-and-indemnification">N. Liberación e indemnización</a>
							</td>
							<td>
								Tú eres totalmente responsable del uso que hagas del servicio.
							</td>
						</tr>
						<tr>
							<td>
								<a style="text-decoration: none" href="#o-changes-to-these-terms">O. Cambios a estos Términos de Servicio</a>
							</td>
							<td>
								Podemos modificar este acuerdo, pero trateremos de aviasarte con 30 días de anticipación de los cambios que afecten tus derechos.
							</td>
						</tr>
						<tr>
							<td><a style="text-decoration: none" href="#p-miscellaneous">P. Miscelánea</a></td>
							<td>
								Consulta esta sección para obtener detalles legales, incluida nuestra elección de ley.
							</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<h2 id="the-OverSOS-terms-of-service" class="terms-header">
			Los Términos de Servicio de OverSOS
		</h2>
		<p>Fecha de vigencia: 23 noviembre 2020</p>
		<div class="my-10">
			<h2 id="a-definitions" class="terms-header">
				A. Definiciones
			</h2>
			<p>
				<strong>Versión corta:</strong>
				<em>
					Utilizamos estos términos básicos en todo el acuerdo y tienen significados específicos. Debes saber a qué nos referimos cuando usamos cada
					uno de los términos. No habrá una prueba al respecto, pero sigue siendo información útil.
				</em>
			</p>
			<ol>
				<li>
					El "Acuerdo" se refiere, colectivamente, a todos los términos, condiciones, avisos contenidos o referenciados en este documento (los
					"Términos de Servicio" o los "Términos") y todas las demás reglas operativas, políticas (incluida la Declaración de Privacidad de OverSOS,
					disponible en https://www.oversos.com/policies/privacy) y los procedimientos que podemos publicar periódicamente en el sitio web. La mayoría
					de las políticas de nuestro sitio están disponibles en https://www.oversos.com/policies/.
				</li>
				<li>
					El "Servicio" se refiere a las aplicaciones, software, productos y servicios proporcionados por OverSOS.
				</li>
				<li>
					El "sitio web" se refiere al sitio web de OverSOS ubicado en https://www.oversos.com/, y todo el contenido, servicios y productos
					proporcionados por OverSOS a través del sitio web. También se refiere a los subdominios propiedad de OverSOS de www.oversos.com.
					Ocasionalmente, los sitios web propiedad de OverSOS pueden proporcionar términos de servicio diferentes o adicionales. Si esos términos
					adicionales entran en conflicto con este Acuerdo, los términos más específicos se aplican a la página o servicio relevante.
				</li>
				<li>
					"El Usuario", "Usted", "Tú", "Tus" y "Su" se refieren a la persona, empresa u organización que visitó o utiliza el Sitio web o el Servicio;
					que accede o utiliza cualquier parte de la Cuenta; o que dirige el uso de la Cuenta en el desempeño de sus funciones. Un usuario debe tener
					al menos 13 años de edad. Pueden aplicarse términos especiales para cuentas comerciales o gubernamentales (consulte la Sección B (5):
					Términos adicionales).
				</li>
				<li>
					“OverSOS”, “Nosotros” y “Nuestro” se refieren a OverSOS, así como a nuestros afiliados, directores, subsidiarias, contratistas,
					licenciantes, funcionarios, agentes y empleados.
				</li>
				<li>
					"Contenido" se refiere al contenido presentado o mostrado a través del sitio web, incluidos, entre otros, texto, datos, artículos, imágenes,
					fotografías, gráficos, software, aplicaciones, diseños, características y otros materiales que están disponibles en el sitio web o que están
					disponibles de otra manera a través del Servicio. El "Contenido" también incluye los Servicios. El "Contenido generado por el usuario" es
					Contenido, escrito o no, creado o cargado por nuestros Usuarios. "Su contenido" o “Tú contenido” es contenido que tú creas o posees.
				</li>
				<li>
					Una "Cuenta" representa tu relación legal con OverSOS. Una "Cuenta de usuario" representa la autorización de un Usuario individual para
					iniciar sesión y utilizar el Servicio y sirve como identidad de usuario en OverSOS.
				</li>
			</ol>
		</div>
		<div class="my-10">
			<h2 id="b-account-terms" class="terms-header">
				B. Términos de la cuenta
			</h2>
			<p>
				<strong>Versión corta:</strong>
				<em>
					Las cuentas de usuario tienen diferentes controles administrativos; un humano debe crear su cuenta; debe tener 13 años o más; debe
					proporcionar una dirección de correo electrónico válida; y no puede tener más de una cuenta gratuita. Tú sólo eres responsable de su Cuenta
					y de todo lo que ocurra mientras inicia sesión o usas tu Cuenta. Tú eres responsable de mantener segura tu cuenta.
				</em>
			</p>
			<div class="my-5">
				<h4 id="1-account-controls" class="terms-subheader">
					1. Controles de cuenta
				</h4>
				<ul>
					<li>
						Usuarios. Sujeto a estos Términos, tú conservas el control administrativo definitivo sobre tu Cuenta de usuario y el Contenido dentro de
						ella.
					</li>
				</ul>
			</div>
			<div class="my-5">
				<h4 id="2-required-information" class="terms-subheader">
					2. Información requerida
				</h4>
				<p>
					Debes proporcionar una dirección de correo electrónico válida para completar el proceso de registro. Cualquier otra información solicitada,
					como su nombre real, es opcional.
				</p>
			</div>
			<div class="my-5">
				<h4 id="3-account-requirements" class="terms-subheader">
					3. Requisitos de cuenta
				</h4>
				<p>
					Tenemos algunas reglas simples para cuentas de usuario en el servicio de OverSOS.
				</p>
				<ul>
					<li>Debes ser humano para crear una cuenta. No se permiten cuentas registradas por "bots" u otros métodos automatizados</li>
					<li>Una persona puede mantener no más de una cuenta.</li>
					<li>
						Debes tener 13 años o más. OverSOS no dirige nuestro Servicio a niños menores de 13 años, y no permitimos ningún Usuario menor de 13 años
						en nuestro Servicio. Si nos enteramos de algún usuario menor de 13 años,
						<a style="text-decoration: none" href="#m-cancellation-and-termination">cancelaremos la cuenta de ese usuario de inmediato</a>. Si es
						residente de un país fuera de España, la edad mínima de su país puede ser mayor; en tal caso, tú eres responsable de cumplir con las leyes
						de tu país.
					</li>
					<li>
						Tu inicio de sesión solo puede ser utilizado por una persona, es decir, un solo inicio de sesión no puede ser compartido por varias
						personas.
					</li>
				</ul>
			</div>
			<div class="my-5">
				<h4 id="4-user-account-security" class="terms-subheader">
					4. Seguridad de la cuenta de usuario
				</h4>
				<p>
					Tú eres responsable de mantener tu cuenta segura mientras usas nuestro Servicio. El contenido de tu cuenta y tu seguridad dependen de ti.
				</p>
				<ul>
					<li>Tú eres responsable de todo el contenido publicado y de la actividad que ocurra en tu Cuenta.</li>
					<li>
						Tú eres responsable de mantener la seguridad de tu Cuenta y contraseña. OverSOS no puede y no será responsable de ninguna pérdida o daño
						por el incumplimiento de esta obligación de seguridad.
					</li>
					<li>
						<router-link :to="{ name: 'Contact' }">
							Notificarás de inmediato a OverSOS
						</router-link>
						si tienes conocimiento de cualquier uso no autorizado o acceso a nuestro Servicio a través de tu Cuenta, incluido cualquier uso no
						autorizado de tu contraseña o Cuenta.
					</li>
				</ul>
			</div>
			<div class="my-5">
				<h4 id="5-additional-terms" class="terms-subheader">
					5. Términos adicionales
				</h4>
				<p>
					En algunas situaciones, los términos de terceros pueden aplicarse a su uso de OverSOS. Ten en cuenta que, si bien estos Términos son nuestro
					acuerdo total contigo, los términos de otras partes rigen sus relaciones contigo.
				</p>
			</div>
		</div>
		<div class="my-10">
			<h2 id="c-acceptable-use" class="terms-header">
				C. Uso aceptable
			</h2>
			<p>
				<strong>Versión corta:</strong>
				<em>
					OverSOS alberga una amplia variedad de proyectos colaborativos y esa colaboración solo funciona cuando nuestros usuarios pueden trabajar
					juntos de buena fe. Al usar el servicio, debes seguir esta Política de uso aceptable, que incluye algunas restricciones sobre el contenido
					que puedes publicar, realizar en el servicio y otras limitaciones. En resumen, sed excelentes el uno con el otro.
				</em>
			</p>
			<div class="my-5">
				<h4 id="1-compliance-with-laws-and-regulations" class="terms-subheader">
					1. Cumplimiento de las leyes y reglamentos
				</h4>
				<p>
					Tu uso del sitio web y el servicio no debe violar ninguna ley aplicable, incluidas las leyes de derechos de autor o marca registrada, las
					leyes de control de exportaciones u otras leyes en su jurisdicción. Tu eres responsable de asegurarte de que tu uso del Servicio cumpla con
					las leyes y las reglamentaciones aplicables.
				</p>
			</div>
			<div class="my-5">
				<h4 id="2-content-restrictions" class="terms-subheader">
					2. Restricciones de contenido
				</h4>
				<p>
					Tu aceptas que bajo ninguna circunstancia cargarás, publicarás, alojarás ni transmitirás ningún contenido que:
				</p>
				<ul>
					<li>es ilegal o promueve actividades ilegales;</li>
					<li>es o contiene contenido sexualmente obsceno;</li>
					<li>es difamatorio, difamatorio o fraudulento;</li>
					<li>es discriminatorio o abusivo hacia cualquier individuo o grupo; ;</li>
					<li>
						contiene o instala cualquier malware o exploits activo, o utiliza nuestra plataforma para la entrega de exploits (como parte de un sistema
						de comando y control); o
					</li>
					<li>
						infringe cualquier derecho de propiedad de cualquier parte, incluidas patentes, marcas comerciales, secretos comerciales, derechos de
						autor, derechos de publicidad u otros derechos.
					</li>
				</ul>
			</div>
			<div class="my-5">
				<h4 id="3-conduct-restrictions" class="terms-subheader">
					3. Restricciones de conducta
				</h4>
				<p>Mientras usas OverSOS, aceptas bajo ninguna circunstancia:</p>
				<ul>
					<li>
						hostigar, abusar, amenazar o incitar a la violencia hacia cualquier individuo o grupo, incluidos los empleados, funcionarios y agentes de
						OverSOS u otros usuarios de OverSOS;
					</li>
					<li>
						usar nuestros servidores para cualquier forma de actividad masiva automatizada excesiva (por ejemplo, correo no deseado), o transmitir
						cualquier otra forma de publicidad o solicitud no solicitada a través de nuestros servidores, como esquemas para hacerse rico rápidamente;
					</li>
					<li>
						intentar interrumpir o alterar los servidores de OverSOS de manera que pueda dañar nuestro sitio web o servicio, colocar una carga
						indebida en los servidores de OverSOS a través de medios automatizados o acceder al servicio de OverSOS de manera que exceda su
						autorización;
					</li>
					<li>
						hacerse pasar por otra persona o entidad, incluidos cualquiera de nuestros empleados o representantes, incluso mediante una asociación
						falsa con OverSOS, o al tergiversar fraudulentamente su identidad o el propósito del sitio; o
					</li>
					<li>
						violar la privacidad de un tercero, como publicar información personal de otra persona sin su consentimiento.
					</li>
				</ul>
			</div>
			<div class="my-5">
				<h4 id="4-services-usage-limits" class="terms-subheader">
					4. Límites de uso de servicios
				</h4>
				<p>
					Aceptas no reproducir, duplicar, copiar, vender, revender o explotar ninguna parte del Servicio, el uso del Servicio o el acceso al Servicio
					sin el permiso expreso por escrito de OverSOS.
				</p>
				<p>
					Las empresas con acceso a OverSOS Talent Pool deberán hacer un uso razonable y no abusivo del mismo. La finalidad de OverSOS Talent Pool es
					permitir a empresas poder buscar proactivamente usuarios de la base de datos de OverSOS que hayan dado su consentimiento y de esa manera
					poder incluirlos en sus ofertas publicadas. Esta característica no está pensada para abusar del acceso a la base de datos y descargar o
					visualizar información de todos los usuarios sin tener un interés genuino por ellos. Si sospechamos que se está haciendo un uso inadecuado
					de OverSOS Talent Pool, lo comunicaremos con la empresa para resolver el asunto. Si transcurridos 7 días hábiles tras nuestro comununicado,
					la empresa no contacta con nosotros, no ha sido posible resolver el asunto o seguimos sospechando del uso inadecuado, nos reservamos el
					derecho de terminar la suscripción activa de la empresa e incluso de terminar su acceso a la plataforma de OverSOS o parte de él.
				</p>
			</div>
			<div class="my-5">
				<h4 id="5-scraping" class="terms-subheader">
					5. Scraping
				</h4>
				<p>
					“Scraping” se refiere a la extracción de datos de nuestro sitio web a través de un proceso automatizado, como un bot o un webcrawler. No se
					refiere a la recopilación de información a través de la API de OverSOS. Puedes hacer “scraping” el sitio web por los siguientes motivos:
				</p>
				<ul>
					<li>
						Los investigadores pueden extraer información pública y no personal de OverSOS para fines de investigación, solo si las publicaciones
						resultantes de esa investigación son de acceso abierto.
					</li>
					<li>
						Los archiveros pueden raspar OverSOS para datos públicos con fines de archivo.
					</li>
				</ul>
				<p>
					No puedes hacer “scraping” de OverSOS para fines de spam, incluso para vender información personal de los usuarios de OverSOS, como
					reclutadores, cazatalentos y bolsas de trabajo.
				</p>
				<p>
					Todo uso de los datos de OverSOS recopilados mediante el “scraping” debe cumplir con la
					<router-link :to="{ name: 'PoliciesPrivacy' }"> Declaración de privacidad de OverSOS </router-link>.
				</p>
			</div>
			<div class="my-5">
				<h4 id="6-privacy" class="terms-subheader">
					6. Privacidad
				</h4>
				<p>
					El uso indebido de la información personal de los usuarios de OverSOS está prohibido.
				</p>
				<p>
					Cualquier persona, entidad o servicio que recopile datos de OverSOS debe cumplir con la
					<router-link :to="{ name: 'PoliciesPrivacy' }">
						Declaración de privacidad de OverSOS
					</router-link>
					, particularmente en lo que respecta a la recopilación de información personal de nuestros usuarios (como se define en la Declaración de
					privacidad de OverSOS). Si recopilas información personal del usuario de OverSOS, aceptas que solo utilizarás la información personal que
					reúnas para el propósito para el cual nuestro usuario la ha autorizado. Tu aceptas que razonablemente protegerás cualquier información
					personal que hayas recopilado de OverSOS, y responderás de inmediato a las quejas, solicitudes de eliminación y solicitudes de "no contacto"
					de OverSOS o usuarios de OverSOS.
				</p>
			</div>
			<div class="my-5">
				<h4 id="7-excessive-bandwidth-use" class="terms-subheader">
					7. Uso excesivo de ancho de banda
				</h4>
				<p>
					Si determinamos que tu uso de ancho de banda es significativamente excesivo en relación con otros clientes de OverSOS, nos reservamos el
					derecho de suspender tu Cuenta o limitar tu movimiento de contenido hasta que puedas reducir tu consumo de ancho de banda.
				</p>
			</div>
			<div class="my-5">
				<h4 id="8-user-protection" class="terms-subheader">
					8. Protección del usuario
				</h4>
				<p>
					Tu aceptas no participar en actividades que perjudiquen significativamente a nuestros usuarios. Resolveremos las disputas a favor de
					proteger a nuestros Usuarios en su conjunto.
				</p>
			</div>
		</div>
		<div class="my-10">
			<h2 id="d-user-generated-content" class="terms-header">
				D. Contenido generado por el usuario
			</h2>
			<p>
				<strong>Versión corta:</strong>
				<em>
					Tú posees el contenido que creas, pero nos otorgas ciertos derechos para que podamos mostrar y compartir el contenido que publicas. Aún
					tienes control sobre tu contenido, y la responsabilidad por él, y los derechos que nos otorgas están limitados a aquellos que necesitamos
					para proporcionar el servicio. Tenemos derecho a eliminar contenido o cerrar Cuentas si es necesario.
				</em>
			</p>
			<div class="my-5">
				<h4 id="1-responsibility-for-user-generated-content" class="terms-subheader">
					1. Responsabilidad por el contenido generado por el usuario
				</h4>
				<p>
					Puedes crear o cargar Contenido generado por el usuario mientras usas el Servicio. Tú eres el único responsable del contenido y de cualquier
					daño resultante de cualquier Contenido generado por el usuario que publiques, cargues, vincules o pongas a disposición a través del
					Servicio, independientemente de la forma de ese Contenido. No somos responsables de ninguna exhibición pública o uso indebido de tu
					Contenido generado por el usuario.
				</p>
			</div>
			<div class="my-5">
				<h4 id="2-OverSOS-may-remove-content" class="terms-subheader">
					2. OverSOS puede eliminar contenido
				</h4>
				<p>
					No preseleccionamos el Contenido generado por el usuario, pero tenemos el derecho (aunque no la obligación) de rechazar o eliminar cualquier
					Contenido generado por el usuario que, a nuestro exclusivo criterio, viole los términos o políticas de OverSOS.
				</p>
			</div>
			<div class="my-5">
				<h4 id="3-ownership-of-content-right-to-post-and-license-grants" class="terms-subheader">
					3. Propiedad del contenido, derecho de publicación y concesión de licencias
				</h4>
				<p>
					Tú retienes la propiedad y la responsabilidad de tu contenido. Si publicas algo que no creaste o no posees los derechos, aceptas que eres
					responsable de cualquier Contenido que publiques; que solo enviarás Contenido que tienes derecho a publicar; y que cumplirás plenamente con
					las licencias de terceros relacionadas con el Contenido que publiques.
				</p>
				<p>
					Debido a que conservas la propiedad y la responsabilidad de tu contenido, necesitamos que nos otorgues a nosotros, y a otros usuarios de
					OverSOS, ciertos permisos legales, enumerados en las Secciones D.4 - D.6. Estas concesiones de licencia se aplican a tu contenido. Si cargas
					Contenido que ya viene con una licencia que le otorgas a OverSOS los permisos que necesitamos para ejecutar nuestro Servicio, no se requiere
					una licencia adicional. Tu comprendes que no recibirás ningún pago por ninguno de los derechos otorgados en las Secciones D.4 - D.6. Las
					licencias que nos concedas finalizarán cuando se elimine tu contenido de nuestros servidores.
				</p>
			</div>
			<div class="my-5">
				<h4 id="4-license-grant-to-us" class="terms-subheader">
					4. Concesión de licencia a nosotros
				</h4>
				<p>
					Tú nos otorgas a nosotros y a nuestros sucesores legales el derecho de almacenar, analizar y mostrar tu contenido, y hacer copias
					incidentales según sea necesario para proporcionar el sitio web y proporcionar el servicio. Esto incluye el derecho a hacer cosas como
					copiarlo a nuestra base de datos y hacer copias de seguridad; mostrártelo a ti y a otros usuarios; analizarlo en un índice de búsqueda o
					analizarlo en nuestros servidores; compartirlo con otros usuarios; y realizarlo, en caso de que su contenido sea algo así como música o
					video.
				</p>
				<p>
					Esta licencia no le otorga a OverSOS el derecho de vender tu Contenido o usarlo de otra manera fuera de nuestra prestación del Servicio.
				</p>
			</div>
			<div class="my-5">
				<h4 id="5-license-grant-to-other-users" class="terms-subheader">
					5. Concesión de licencia a otros usuarios
				</h4>
				<p>
					Otros pueden ver cualquier Contenido generado por el usuario que publiques públicamente, incluidas publicaciones, comentarios y
					contribuciones a las carpetas del curso.
				</p>
				<p>
					El contenido generado por el usuario podrá ser visible en la bolsa de empleo de OverSOS para las empresas de las cuales el usuario haya
					aplicado a ofertas de empleo. Si el usuario accede a compartir su perfil en OverSOS Talent Pool, entonces todas las empresas con acceso a
					este servicio podrán visualizar su perfil (Estudios, Descripción, Intereses, Idiomas, Aptitudes, Proyectos, etc.) y sus documentos tales
					como CV, Expediente o Carta de motivación que el usuario haya decidido marcar como principales.
				</p>
			</div>
			<div class="my-5">
				<h4 id="6-moral-rights" class="terms-subheader">
					6. Derechos morales
				</h4>
				<p>
					Tu retienes todos los derechos morales sobre tu Contenido que cargas, publicas o envías a cualquier parte del Servicio, incluidos los
					derechos de integridad y atribución. Sin embargo, renuncias a estos derechos y aceptas no hacer valerlos contra nosotros, para permitirnos
					ejercer razonablemente los derechos otorgados en la Sección D.4, pero no de otra manera.
				</p>
				<p>
					En la medida en que este acuerdo no sea exigible por la ley aplicable, tú le otorgas a OverSOS los derechos que necesitamos para usar tu
					contenido sin atribución y para hacer adaptaciones razonables de tu contenido según sea necesario para prestar el sitio web y proporcionar
					el servicio.
				</p>
			</div>
		</div>
		<div class="my-10">
			<h2 id="e-course-folders" class="terms-header">
				E. Carpetas de asignatura
			</h2>
			<p>
				<strong>Versión corta:</strong>
				<em>
					Puedes tener acceso a las carpetas de las asignaturas. Tratamos el contenido de estas carpetas como confidencial y sólo accedemos a él por
					razones de soporte, con tu consentimiento o si es necesario por razones de seguridad.
				</em>
			</p>
			<div class="my-5">
				<h4 id="1-control-of-course-folders" class="terms-subheader">
					1. Control de carpetas de asignaturas.
				</h4>
				<p>
					En OverSOS existe una comunidad para compartir material académico entre estudiantes de un mismo estudio dividido por asignaturas y esta
					comunidad solo es accesible para usuarios con perfil de estudiante.
				</p>
				<p>
					Algunas asignaturas de la plataforma pueden tener carpetas que contienen contenido generado por el usuario e información del curso dado.
					Estas carpetas están alojadas en cuentas privadas de OverSOS en Google Drive y estas cuentas funcionan como editores de un variado número de
					estudios. Estos editores consienten el acceso de esta cuenta privada y carpetas a OverSOS, para que nosotros podamos acceder y controlar sus
					miembros compartidos.
				</p>
				<p>
					Puedes cargar contenido en estas carpetas y el contenido cargado será responsable del usuario que realiza la carga.
				</p>
			</div>
			<div class="my-5">
				<h4 id="2-confidentiality-of-course-folders" class="terms-subheader">
					2. Confidencialidad de las carpetas de las asignaturas.
				</h4>
				<p>
					OverSOS considera que el contenido de las carpetas de las asignaturas es confidencial para su uso y distribución, por lo que OverSOS
					protegerá el contenido del uso no autorizado, el acceso o la divulgación al solicitar una verificación del estado del estudiante del
					usuario.
				</p>
			</div>
			<div class="my-5">
				<h4 id="3-access" class="terms-subheader">
					3. Acceso.
				</h4>
				<p>
					Los empleados de OverSOS pueden acceder al contenido de las carpetas de la asignatura en las siguientes situaciones:
				</p>
				<ul>
					<li>Con tu consentimiento y conocimiento, por razones de soporte.</li>
					<li>
						Cuando se requiere acceso por razones de seguridad, incluso cuando se requiere acceso para mantener la confidencialidad, integridad,
						disponibilidad y resistencia continuas de los sistemas y el Servicio de OverSOS.
					</li>
				</ul>
				<p>
					Un usuario podrá acceder al contenido de la carpeta de una asignatura sólo si el usuario es un estudiante verificado del estudio o facultad
					de la asignatura en cuestión.
				</p>
				<p>
					Es posible que te demos acceso a la carpeta de una asignatura, sólo después de haber verificado que realmente eres un estudiante del estudio
					y la facultad a la que pertenece la asignatura. Para que podamos verificar el estado de estudiante, necesitamos verificar la veracidad del
					correo electrónico del estudiante. Guardaremos esta información en nuestros servidores para su uso posterior en términos de comunicaciones
					entre tú, el usuario final, los editores de tu facultad o estudio y OverSOS.
				</p>
				<p>
					Por defecto, puedes acceder a hasta 8 carpetas de asignaturas compartidos. Podemos cambiar eso si nos demuestras que necesitas más acceso.
				</p>
			</div>
			<div class="my-5">
				<h4 id="4-exclusions" class="terms-subheader">
					4. Exclusiones.
				</h4>
				<p>
					Si tenemos razones para creer que el contenido de la carpeta de una asignatura
					<router-link
						:to="{
							path: 'policies/privacy#how-we-respond-to-compelled-disclosure'
						}"
					>
						infringe la ley
					</router-link>
					o estos Términos, tenemos el derecho de acceder a ellos, revisarlos y eliminarlos. Además, la ley puede obligarnos a revelar el contenido de
					la carpeta de cualquier asignatura.
				</p>
			</div>
		</div>
		<div class="my-10">
			<h2 id="f-copyright-infringement-and-dmca-policy" class="terms-header">
				F. Infracción de derechos de autor y política de DMCA
			</h2>
			<p>
				Si crees que el contenido de nuestro sitio web viola tus derechos de autor, contáctanos. Si eres propietario de derechos de autor y crees que
				el contenido de OverSOS viola tus derechos, contáctanos a través de nuestro formulario de contacto o envíanos un correo electrónico. Puede
				haber consecuencias legales por enviar un aviso de eliminación falso o frívolo. Antes de enviar una solicitud de eliminación, debes considerar
				los usos legales, como el uso justo y los usos con licencia.
			</p>
			<p>
				Cancelaremos las Cuentas de infractores reincidentes de esta política.
			</p>
		</div>
		<div class="my-10">
			<h2 id="g-intellectual-property-notice" class="terms-header">
				G. Aviso de propiedad intelectual
			</h2>
			<p>
				<strong>Versión corta:</strong>
				<em>
					Somos dueños del servicio y de todo nuestro contenido. Para que puedas usar nuestro contenido, te otorgamos ciertos derechos, pero solo
					puedes usar nuestro contenido de la manera que hemos permitido.
				</em>
			</p>
			<div class="my-5">
				<h4 id="1-OverSOSs-rights-to-content" class="terms-subheader">
					1. Derechos de OverSOS al contenido
				</h4>
				<p>
					OverSOS y nuestros otorgantes de licencias, proveedores, agentes y/o nuestros proveedores de contenido conservan la propiedad de todos los
					derechos de propiedad intelectual de cualquier tipo relacionados con el Sitio web y el Servicio. Nos reservamos todos los derechos que no se
					le otorgan expresamente en virtud de este Acuerdo o de la ley. El aspecto del Sitio web y el Servicio está protegido por derechos de autor ©
					OverSOS, Inc. Todos los derechos reservados. No puedes duplicar, copiar o reutilizar ninguna parte de HTML / CSS, Javascript o elementos o
					conceptos de diseño visual sin el permiso expreso por escrito de OverSOS.
				</p>
			</div>
			<div class="my-5">
				<h4 id="2-license-to-OverSOS-policies" class="terms-subheader">
					2. Licencia para Políticas de OverSOS
				</h4>
				<p>
					Este Acuerdo está licenciado bajo la licencia
					<a style="text-decoration: none" href="https://creativecommons.org/publicdomain/zero/1.0/">Creative Commons Zero</a>.
				</p>
			</div>
		</div>
		<div class="my-10">
			<h2 id="h-advertising-on-OverSOS" class="terms-header">
				H. Publicidad en OverSOS
			</h2>
			<p>
				<strong>Versión corta:</strong>
				<em>
					Generalmente no prohibimos el uso de OverSOS para publicidad. Sin embargo, esperamos que nuestros usuarios sigan ciertas limitaciones para
					que OverSOS no se convierta en un refugio de spam. Nadie quiere eso.
				</em>
			</p>
			<div class="my-5">
				<h4 id="1-spam-course-folders" class="terms-subheader">
					1. Carpetas de la asignatura
				</h4>
				<p>
					Las carpetas de la asignatura están destinadas a alojar contenido. Puedes incluir imágenes estáticas, enlaces y texto promocional en los
					documentos asociados, pero deben estar relacionados con el proyecto que se aloja en OverSOS.
				</p>
				<p>
					No puedes anunciarte en las carpetas de otros cursos, como publicar contenido masivo monetizado o excesivo en problemas.
				</p>
			</div>
			<div class="my-5">
				<h4 id="2-spamming-and-inappropriate-use-of-OverSOS" class="terms-subheader">
					2. Spam y uso inapropiado de OverSOS
				</h4>
				<p>
					El Contenido publicitario, como todo Contenido, no debe violar la ley o estos Términos de uso, por ejemplo, a través de una actividad masiva
					excesiva como el spam. Nos reservamos el derecho de eliminar cualquier publicidad que, a nuestro exclusivo criterio, viole cualquier término
					o política de OverSOS.
				</p>
			</div>
		</div>
		<div class="my-10">
			<h2 id="i-payment" class="terms-header">
				I. Pago
			</h2>
			<p>
				<strong>Versión corta:</strong>
				<em>
					Eres responsable de cualquier cargo asociado con tu uso de OverSOS. Somos responsables de comunicarte esos cobros con claridad y precisión y
					de hacerte saber con mucha antelación si esos precios cambian.
				</em>
			</p>
			<div class="my-5">
				<h4 id="1-prices" class="terms-subheader">
					1. Precios
				</h4>
				<p>
					Nuestros precios y términos de pago están disponibles en
					<router-link :to="{ name: 'Pricing' }" target="_blank">enterprise.oversos.com</router-link>. Si aceptas un precio de suscripción, ese
					seguirá siendo tu precio durante la duración del plazo de pago; sin embargo, los precios están sujetos a cambios al final de un plazo de
					pago.
				</p>
			</div>
			<div class="my-5">
				<h4 id="2-upgrades-downgrades-changes" class="terms-subheader">
					2. Mejoras, descensos y cambios
				</h4>
				<ul>
					<li>
						Te cobraremos inmediatamente cuando pase del plan gratuito a cualquier plan de pago.
					</li>
					<li>
						Si cambias de un plan de facturación mensual a un plan de facturación anual, OverSOS te cobrará por un año completo en la próxima fecha de
						facturación mensual.
					</li>
					<li>
						Si mejoras a un mayor nivel de servicio, te cobraremos el plan actualizado inmediatamente.
					</li>
					<li>
						Puedes cambiar tu nivel de servicio en cualquier momento eligiendo una opción de plan o entrando a tu configuración de suscripciones. Si
						decides bajar la categoría de tu Cuenta, puedes perder el acceso al Contenido, características o capacidad de tu Cuenta. Consulta nuestra
						sección sobre Cancelación para obtener información sobre cómo obtener una copia de ese contenido.
					</li>
				</ul>
			</div>
			<div class="my-5">
				<h4 id="3-invoices-no-refunds" class="terms-subheader">
					3. Programación de facturación; no reembolsos
				</h4>
				<p>
					<b>Pago basado en el plan</b> para planes de pago mensuales o anuales, el Servicio se cobra con antelación de forma mensual o anual
					respectivamente y no es reembolsable. No habrá reembolsos o crédito para meses parciales de servicio, reembolsos o reembolsos durante meses
					sin usar con una cuenta abierta; sin embargo, el servicio permanecerá activo durante el período de facturación pagado. Para tratar a todos
					por igual, no se harán excepciones.
				</p>
				<p>
					<b>Facturación</b>. Para usuarios facturados, el usuario acepta pagar los cobros en su totalidad, por adelantado, en euros (€). El usuario
					debe pagar las cuotas dentro de treinta (30) días a partir de la fecha de facturación de OverSOS. Los importes pagaderos en virtud de este
					Acuerdo no son reembolsables, excepto que se estipule de otro modo en este Acuerdo. Si el usuario no paga las cuotas a tiempo, OverSOS se
					reserva el derecho, además de tomar cualquier otra acción conforme a derecho o equidad, a (i) cobrar intereses sobre importes vencidos en
					1.0% al mes o el tipo de interés más alto permitido por la ley, el que sea menor y a cobrar todos los gastos de recuperación y (ii) cancelar
					el formulario de pedido aplicable. El usuario es el único responsable de todos los impuestos, tarifas, obligaciones y valoraciones
					gubernamentales (a excepción de los impuestos basados en los ingresos netos de OverSOS) que se imponen o vencen en relación con el presente
					Acuerdo.
				</p>
			</div>
			<div class="my-5">
				<h4 id="4-authorization" class="terms-subheader">
					4. Autorización
				</h4>
				<p>
					Al aceptar estos términos, nos das permiso para hacer un cargo en los métodos de pago aprobados para los cobros que autorizas para OverSOS.
				</p>
			</div>
			<div class="my-5">
				<h4 id="5-payment-responsability" class="terms-subheader">
					5. Responsabilidad de pago
				</h4>
				<p>
					Eres responsable de todas las cuotas, incluyendo los impuestos, asociados con tu uso del Servicio. Al utilizar el Servicio, aceptas pagar a
					OverSOS cualquier cobro incurrido en relación con tu uso del Servicio. Si impugnas el asunto, ponte en contacto con el soporte de OverSOS.
					Eres responsable de proporcionarnos un medio de pago válido para las pagar las Cuentas. Las cuentas gratuitas no están obligadas a
					proporcionar información de pago.
				</p>
			</div>
		</div>
		<div class="my-10">
			<h2 id="j-cancellation-and-termination" class="terms-header">
				J. Cancelación y terminación
			</h2>
			<p>
				<strong>Versión corta:</strong>
				<em>Puedes cerrar tu cuenta en cualquier momento. Si lo haces, trataremos tu información de manera responsable.</em>
			</p>
			<div class="my-5">
				<h4 id="1-account-cancellation" class="terms-subheader">
					1. Cancelación de cuenta
				</h4>
				<p>
					Es tu responsabilidad cancelar adecuadamente tu cuenta con OverSOS. Puedes cancelar tu cuenta en cualquier momento accediendo a su
					configuración. La pantalla Cuenta proporciona un enlace de cancelación simple y sin preguntas. Por lo general, no podemos cancelar Cuentas
					en respuesta a un correo electrónico o solicitud telefónica. No obstante, es posible en situaciones extraordinarias si la identidad es
					verificada.
				</p>
			</div>
			<div class="my-5">
				<h4 id="2-upon-cancellation" class="terms-subheader">
					2. Tras la cancelación
				</h4>
				<p>
					Retendremos y utilizaremos tu información según sea necesario para cumplir con nuestras obligaciones legales, resolver disputas y hacer
					cumplir nuestros acuerdos, pero salvo los requisitos legales, eliminaremos tu perfil completo y el Contenido de tu usuario dentro de los 90
					días de la cancelación o terminación (aunque cierta información puede permanecer en copias de seguridad cifradas). Esta información no se
					puede recuperar una vez que se cancela tu cuenta.
				</p>
				<p>
					No eliminaremos el Contenido que hayas contribuido a las carpetas de otros cursos.
				</p>
				<p>
					Si lo solicitas, haremos un esfuerzo razonable para proporcionar al propietario de la Cuenta una copia de los contenidos legales y no
					infractores de la Cuenta después de la cancelación o baja de la cuenta. Debes realizar esta solicitud dentro de los 90 días posteriores a la
					cancelación o baja de la cuenta.
				</p>
			</div>
			<div class="my-5">
				<h4 id="3-OverSOS-may-terminate" class="terms-subheader">
					3. OverSOS puede cancelar
				</h4>
				<p>
					OverSOS tiene el derecho de suspender o cancelar tu acceso a todo o parte del sitio web en cualquier momento, con o sin causa, con o sin
					aviso, con efecto inmediato. OverSOS se reserva el derecho de rechazar el servicio a cualquier persona por cualquier motivo en cualquier
					momento.
				</p>
			</div>
			<div class="my-5">
				<h4 id="4-survival" class="terms-subheader">
					4. Supervivencia
				</h4>
				<p>
					Todas las disposiciones de este Acuerdo que, por su naturaleza, deben sobrevivir a la terminación, sobrevivirán a la terminación, incluidas,
					entre otras, las disposiciones de propiedad, renuncias de garantía, indemnización y limitaciones de responsabilidad.
				</p>
			</div>
		</div>
		<div class="my-10">
			<h2 id="k-communications-with-OverSOS" class="terms-header">
				K. Comunicaciones con OverSOS
			</h2>
			<p>
				<strong>Versión corta:</strong>
				<em>Utilizamos el correo electrónico y otros medios electrónicos para mantenernos en contacto con nuestros usuarios.</em>
			</p>
			<div class="my-5">
				<h4 id="1-electronic-communication-required" class="terms-subheader">
					1. Comunicación electrónica requerida
				</h4>
				<p>
					Para fines contractuales, tú (1) aceptas recibir nuestras comunicaciones en forma electrónica a través de la dirección de correo electrónico
					que has proporcionado mediante el Servicio; y (2) aceptas que todos los Términos de servicio, acuerdos, avisos, divulgaciones y otras
					comunicaciones que le proporcionamos electrónicamente satisfacen cualquier requisito legal que esas comunicaciones cumplirían si fueran en
					papel. Esta sección no afecta tus derechos no renunciables.
				</p>
			</div>
			<div class="my-5">
				<h4 id="2-legal-notice-to-OverSOS-must-be-in-writing" class="terms-subheader">
					2. El aviso legal para OverSOS debe ser por escrito
				</h4>
				<p>
					Las comunicaciones realizadas por correo electrónico o el sistema de mensajería del soporte de OverSOS no constituirán un aviso legal para
					OverSOS o cualquiera de sus funcionarios, empleados, agentes o representantes en cualquier situación en la que se requiera una notificación
					a OverSOS por contrato o cualquier ley o regulación.
				</p>
			</div>
			<div class="my-5">
				<h4 id="3-no-phone-support" class="terms-subheader">
					3. Sin soporte telefónico
				</h4>
				<p>
					OverSOS solo ofrece soporte por correo electrónico, comunicaciones en servicio y mensajes electrónicos. No ofrecemos soporte telefónico.
				</p>
			</div>
		</div>
		<div class="my-10">
			<h2 id="l-disclaimer-of-warranties" class="terms-header">
				L. Renuncia de garantías
			</h2>
			<p>
				<strong>Versión corta:</strong>
				<em>
					Brindamos nuestro servicio tal cual y no hacemos promesas ni garantías sobre este servicio. Por favor lee esta sección atentamente; Debes
					entender qué esperar.
				</em>
			</p>
			<p>
				OverSOS proporciona el sitio web y el servicio "tal cual" y "según esté disponible", sin garantía de ningún tipo. Sin limitar esto,
				renunciamos expresamente a todas las garantías, ya sean expresas, implícitas o estatutarias, con respecto al sitio web y el Servicio,
				incluidas, entre otras, cualquier garantía de comerciabilidad, idoneidad para un propósito particular, título, seguridad, precisión y no
				infracción.
			</p>
			<p>
				OverSOS no garantiza que el Servicio cumpla con sus requisitos; que el Servicio será ininterrumpido, oportuno, seguro o sin errores; que la
				información proporcionada a través del Servicio es precisa, confiable o correcta; que cualquier defecto o error será corregido; que el
				Servicio estará disponible en cualquier momento o lugar en particular; o que el Servicio esté libre de virus u otros componentes dañinos. Tu
				asumes toda la responsabilidad y el riesgo de pérdida resultante de la descarga y/o uso de archivos, información, contenido u otro material
				obtenido del Servicio.
			</p>
		</div>
		<div class="my-10">
			<h2 id="m-limitation-of-liability" class="terms-header">
				M. Limitación de responsabilidad
			</h2>
			<p>
				<strong>Versión corta:</strong>
				<em>
					No seremos responsables de los daños o pérdidas que surjan de tu uso o incapacidad para usar el servicio o que deriven de este acuerdo. Por
					favor lee esta sección atentamente; limita nuestras obligaciones para contigo.
				</em>
			</p>
			<p>
				Comprendes y aceptas que no seremos responsables ante ti ni ante ningún tercero por ninguna pérdida de beneficios, uso, buena voluntad, datos,
				ni por ningún daño incidental, indirecto, especial, consecuente o ejemplar, que surja como resultado de:
			</p>
			<ul>
				<li>El uso, divulgación o exhibición de tu Contenido generado por el usuario.</li>
				<li>Tu uso o incapacidad para usar el Servicio</li>
				<li>Cualquier modificación, cambio de precio, suspensión o interrupción del Servicio.</li>
				<li>El Servicio en general o el software o sistemas que hacen que el Servicio esté disponible.</li>
				<li>Acceso no autorizado o alteraciones de tus transmisiones o datos.</li>
				<li>Declaraciones o conducta de un tercero en el Servicio.</li>
				<li>Cualquier otra interacción del usuario que ingreses o reciba a través del uso del Servicio.</li>
				<li>Cualquier otro asunto relacionado con el Servicio.</li>
			</ul>
			<p>
				Nuestra responsabilidad es limitada, ya sea que se nos haya informado o no de la posibilidad de tales daños, e incluso si se encuentra que un
				remedio establecido en este Acuerdo ha fallado en su propósito esencial. No tendremos ninguna responsabilidad por cualquier falla o retraso
				debido a asuntos más allá de nuestro control razonable.
			</p>
		</div>
		<div class="my-10">
			<h2 id="n-release-and-indemnification" class="terms-header">
				N. Liberación e indemnización
			</h2>
			<p>
				<strong>Versión corta:</strong>
				<em>Eres responsable de tu uso del servicio. Si dañas a otra persona o tienes una disputa con otra persona, no estaremos involucrados.</em>
			</p>
			<p>
				Si tienes una disputa con uno o más Usuarios, aceptas liberar a OverSOS de todos y cada uno de los reclamos, demandas y daños (reales y
				consecuentes) de todo tipo y naturaleza, conocidos y desconocidos, que surjan o estén relacionados de alguna manera con tales disputas.
			</p>
			<p>
				Aceptas indemnizarnos, defendernos y eximirnos de toda responsabilidad contra cualquier reclamo, responsabilidad y gasto, incluidos los
				honorarios de abogados, que surjan de tu uso del Sitio web y el Servicio, incluyendo, entre otros, tu violación de este Acuerdo, siempre que
				OverSOS:
			</p>
			<ol>
				<li>Te avise por escrito de inmediato de la reclamación, demanda, pleito o procedimiento.</li>
				<li>
					Te otorgue el control exclusivo de la defensa y la liquidación del reclamo, la demanda, el pleito o el procedimiento (siempre que no puedas
					liquidar ningún reclamo, demanda, pleito o procedimiento a menos que el acuerdo exonere incondicionalmente a OverSOS de toda
					responsabilidad).
				</li>
				<li>Te brinde toda la asistencia razonable, a tu cargo.</li>
			</ol>
		</div>
		<div class="my-10">
			<h2 id="o-changes-to-these-terms" class="terms-header">
				O. Cambios a estos términos
			</h2>
			<p>
				<strong>Versión corta:</strong>
				<em>
					Queremos que nuestros usuarios estén informados de los cambios importantes en nuestros términos, pero algunos cambios no son tan
					importantes, no queremos molestarte cada vez que arreglemos un error tipográfico. Por lo tanto, aunque podemos modificar este acuerdo en
					cualquier momento, notificaremos a los usuarios, en la medida de lo posible, sobre cualquier cambio que afecte a sus derechos y daremos
					tiempo para adaptarse a ellos.
				</em>
			</p>
			<p>
				Nos reservamos el derecho, a nuestra sola discreción, de modificar estos Términos de servicio en cualquier momento y actualizaremos estos
				Términos de servicio en caso de tales modificaciones. Intentaremos notificar a nuestros usuarios, en la medida de lo posible, sobre cambios
				materiales en este Acuerdo al menos 30 días antes de que el cambio entre en vigencia mediante la publicación de un aviso en nuestro sitio web.
				Para modificaciones no materiales, el uso continuo del sitio web constituye un acuerdo con nuestras revisiones de estos Términos de Servicio.
			</p>
			<p>
				Nos reservamos el derecho en cualquier momento y de vez en cuando de modificar o descontinuar, temporal o permanentemente, el sitio web (o
				cualquier parte de éste) con o sin previo aviso.
			</p>
		</div>
		<div class="my-10">
			<h2 id="p-miscellaneous" class="terms-header">
				P. Miscelánea
			</h2>
			<div class="my-5">
				<h4 id="1-governing-law" class="terms-subheader">
					1. Ley aplicable
				</h4>
				<p>
					Excepto en la medida en que la ley aplicable disponga lo contrario, este Acuerdo entre tú y OverSOS y cualquier acceso o uso del Sitio web o
					del Servicio se rigen por las leyes del país de España, sin tener en cuenta las disposiciones sobre conflictos de leyes. Tú y OverSOS
					acordáis someteros a la jurisdicción exclusiva de los tribunales ubicados en la ciudad y la provincia de Barcelona, España.
				</p>
			</div>
			<div class="my-5">
				<h4 id="2-non-assignability" class="terms-subheader">
					2. No asignabilidad
				</h4>
				<p>
					OverSOS puede asignar o delegar estos Términos de servicio y / o la
					<router-link :to="{ name: 'PoliciesPrivacy' }"> Declaración de privacidad de OverSOS </router-link>, en su totalidad o en parte, a cualquier
					persona o entidad en cualquier momento con o sin tu consentimiento, incluida la concesión de la licencia en la Sección D.4. No puedes
					asignar ni delegar ningún derecho u obligación en virtud de los Términos de servicio o la Declaración de privacidad sin nuestro
					consentimiento previo por escrito, y cualquier asignación y delegación no autorizada por ti es nula.
				</p>
			</div>
			<div class="my-5">
				<h4 id="3-section-headings-and-summaries" class="terms-subheader">
					3. Encabezados de sección y resúmenes
				</h4>
				<p>
					En este Acuerdo, cada sección incluye títulos y resúmenes breves de los siguientes términos y condiciones. Estos títulos de sección y
					resúmenes breves no son legalmente vinculantes.
				</p>
			</div>
			<div class="my-5">
				<h4 id="4-severability-no-waiver-and-survival" class="terms-subheader">
					4. Divisibilidad, no renuncia y supervivencia
				</h4>
				<p>
					Si alguna parte de este Acuerdo se considera inválida o inaplicable, esa parte del Acuerdo se interpretará para reflejar la intención
					original de las partes. Las porciones restantes permanecerán en pleno vigor y efecto. Cualquier incumplimiento por parte de OverSOS de hacer
					cumplir alguna disposición de este Acuerdo no se considerará una renuncia a nuestro derecho de hacer cumplir dicha disposición. Nuestros
					derechos bajo este Acuerdo sobrevivirán a la terminación de este Acuerdo.
				</p>
			</div>
			<div class="my-5">
				<h4 id="5-amendments-complete-agreement" class="terms-subheader">
					5. Enmiendas; Acuerdo completo
				</h4>
				<p>
					Este Acuerdo solo puede modificarse mediante una enmienda por escrito firmada por un representante autorizado de OverSOS, o mediante la
					publicación por parte de OverSOS de una versión revisada de acuerdo con la
					<a style="text-decoration: none" href="#n-changes-to-these-terms">Sección N. Cambios a estos Términos</a>. Estos Términos de servicio, junto
					con la Declaración de privacidad de OverSOS, representan la declaración completa y exclusiva del acuerdo entre tu y nosotros. Este Acuerdo
					reemplaza cualquier propuesta o acuerdo previo oral o escrito, y cualquier otra comunicación entre tú y OverSOS relacionada con el tema de
					estos términos, incluidos los acuerdos de confidencialidad o no divulgación.
				</p>
			</div>
			<div class="my-5">
				<h4 id="6-questions" class="terms-subheader">
					6. Preguntas
				</h4>
				<p>
					¿Preguntas sobre los Términos de servicio?
					<router-link :to="{ name: 'Contact' }"> Contáctanos </router-link>.
				</p>
			</div>
		</div>
	</v-container>
</template>

<script>
export default {
	metaInfo() {
		return {
			title: `${this.$t('policies.title')} - ${this.$t('policies.terms')}`
		}
	}
}
</script>

<style scoped>
.terms-header {
	color: firebrick;
	border-bottom: 2px solid grey;
}
.terms-subheader {
	color: indianred;
}
</style>
